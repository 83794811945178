.p-menu {
  background-color: $c-surface_surface_white;
  box-shadow: 0px 1px 3px $box-shadow;
  border-radius: 4px;
  min-width: 22rem;
  padding: 2px;

  .p-menuitem {
    display: flex;
    align-items: center;

    &:hover {
      border-radius: 4px;
      background-color: $c-obsolete-grey_40;
    }

    &.danger .p-menuitem-link {
      color: $c-status_red_100;
    }
  }

  .p-menuitem-link {
    color: $c-primary_dark_blue;
    padding-bottom: 1px;
    width: 100%;
  }
}

.p-menuitem-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.p-menuitem-text {
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.p-disabled .p-menuitem-text {
  opacity: 0.4;
}

.p-menuitem {
  height: 40px;
  padding: 0.8rem 1.2rem;
}

.p-menu-separator {
  border-bottom: solid 1px $c-obsolete-grey_40;
  margin: 4px 0;
}

.p-menuitem-icon {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-right: 0.8rem;
  width: 20px;
}

.p-menubar {
  .p-submenu-icon::after {
    @include fa-icon;
    font-family: fa-content($fa-style-family);
    content: fa-content($fa-var-angle-down);
    padding-left: 4px;
  }

  .p-submenu-list {
    background-color: $c-surface_surface_white;
    box-shadow: 0px 2px 4px $box-shadow;
    border-radius: 4px;
    padding: 2px;
    margin-top: 14px;
    min-width: 220px;
    left: 0px;

    .p-menuitem-link {
      color: $c-primary_dark_blue;
    }
    .p-menuitem {
      margin-right: 0px !important;
    }
    .p-menuitem-active {
      &:hover {
        background-color: $c-obsolete-grey_40;
        border-radius: 4px;
      }
    }
  }

  .p-menuitem {
    border-radius: 4px;
    margin-right: 8px !important;

    &:hover {
      background-color: rgba($c-surface_surface_white, 0.2);
      color: $c-surface_surface_white;
    }
  }

  .p-menuitem-active {
    background-color: rgba($c-obsolete-grey_40, 0.2);
    border-radius: 4px;

    .p-submenu-icon::after {
      @include fa-icon;
      font-family: fa-content($fa-style-family);
      content: fa-content($fa-var-angle-up);
      padding-left: 4px;
    }
  }

  .p-menuitem-link {
    width: "fit-content";
    color: $c-surface_surface_white;
  }
}

@media screen and (max-width: 768px) {
  .pi {
    display: inline-block;
    font: normal normal normal 14px/1 ForkAwesome;
    font-size: 3rem;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .pi-bars::before {
    content: "\f0c9";
  }
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 4rem;
    height: 4rem;
    color: $c-surface_surface_white;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: $c-surface_surface_100;
    background: $c-surface_surface_white;
  }
  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0 3rem 0;
    background: $c-surface_surface_white;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    width: 100%;
    min-width: 250px;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid $c-surface_surface_white;
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: $c-surface_surface_90;
    border-radius: 0;
    transition: box-shadow 0.2s;
    user-select: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: $c-surface_surface_90;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: $c-surface_surface_90;
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: $c-surface_surface_90;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: $c-surface_surface_white;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: $c-surface_surface_90;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: $c-surface_surface_90;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: $c-surface_surface_90;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem $c-surface_surface_10;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}

.p-tabmenu {
  .p-tabmenuitem {
    padding: 1.2rem 1.6rem;

    &.p-highlight {
      border-bottom: solid 4px $c-primary_teal_default;
      &.p-error {
        border-bottom: solid 4px $c-status_red_12;
      }
    }

    &:not(.p-highlight):hover {
      border-bottom: solid 4px rgba($c-primary_teal_default, 0.4);
    }
    &.p-error:hover {
      border-bottom: solid 4px rgba($c-status_red_100, 0.4);
    }
  }

  .p-tabmenuitem + .p-tabmenuitem {
    margin-left: 0.4rem;
  }

  .p-menuitem-link {
    color: $c-surface_surface_white;
  }
}
