.page-dashboard {
  .title {
    font-size: 5vh;
    line-height: 1.5em;
    letter-spacing: 0em;
    margin-top: 20vh;
    margin-bottom: 12px;
    .svg-inline--fa {
      margin-right: 0.4rem;
    }
  }
  .info {
    font-size: 2vh;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0em;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .tenant {
    text-align: right;
    display: flex;
    margin-bottom: 16px;
    padding-left: 0;
    flex-direction: row-reverse;
    position: absolute;
    right: 5vw;
    top: 7vh;

    .company {
      font-weight: 400;
      line-height: 1em;
      font-size: 1.5vw;
      margin-top: 0.35em;
    }
    .domain {
      font-weight: 400;
      font-size: 0.75vw;
      line-height: 1em;
    }
    .p-avatar {
      margin-left: 1em;
      &.p-avatar-lg {
        font-size: 1.7vh;
      }
    }
  }
  .tile-arrow {
    color: $c-primary_dark_blue;
    background-color: $c-obsolete-grey_40;
    width: 24px;
    height: 24px;
    border-radius: 0 4px;
    float: right;

    visibility: hidden;

    .svg-inline--fa {
      padding-top: 8px;
    }
    .fa,
    .fa-regular {
      font-size: 1.2rem;
      line-height: 2.4rem;

      &.fa-chevron-down {
        transition: all 0.2s;
      }
    }
  }
  .tile {
    background: $c-surface_surface_white;
    box-shadow: 0px 1px 3px $box-shadow;
    border-radius: 4px;

    &:hover {
      .tile-arrow {
        visibility: visible;
      }
    }
  }
  .tile-active {
    border: 2px solid $c-primary_teal_hover;
    cursor: pointer;
    .tile-content {
      margin-left: 20px;
    }
    .tile-arrow {
      color: $c-surface_surface_white;
      background-color: $c-primary_teal_hover;
      margin: -2px;
    }
    .stats-tile {
      margin-top: -2px;
      margin-left: 2px;
    }
  }
  .answers {
    .tile.small {
      .tile-content {
        margin-left: 2em;
      }
    }
    .tile-active {
      .tile-content {
        margin-left: 2em;
      }
    }
  }
  .large {
    width: 550px;
    height: 296px;
  }
  .small {
    width: 267px;
    height: 140px;
    padding-bottom: 16px;
  }
  .tiles {
    display: flex;
    gap: 16px;
    &.answers {
      float: left;
      width: 55%;
      padding-left: 5%;
    }
  }
  .tile-content-small {
    align-content: center;
    justify-content: center;
    flex-direction: column;
  }
  .tile-content {
    display: flex;
    margin-left: 24px;
    height: 100%;

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5em;
      margin: 0;
    }
    .counter-tile {
      .count {
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
      }
      .warning {
        color: $c-status_orange_100;
        width: 24px;
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        padding-right: 4px;
      }
    }

    .stats-tile {
      .title {
        text-align: left;
        margin: 12px 0;
      }
      .chart {
        font-weight: 400;
        font-size: 56px;
        line-height: 32px;
        .icon {
          color: $c-obsolete-grey_100;
          width: 80px;
          height: 80px;
        }
      }
    }

    .header-tile {
      color: $c-primary_dark_blue;
      .title {
        text-align: left;
        margin: 2px 0 8px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .text {
        text-align: left;
        margin: 8px 0 8px 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
      }
      .icon {
        background: conic-gradient(
          from 135deg at 50% 50%,
          #9bffe4 -54.95deg,
          #9035e0 90.6deg,
          #4bade1 188.4deg,
          #9bffe4 305.05deg,
          #9035e0 450.6deg
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        margin-top: 12px;
      }
      .icon-container {
        text-align: left;
      }
    }
  }
  .tiles-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 550px;
    
    &.answers {
      width: 100%;

      .small {
        width: calc(50% - 8px);
        height: 14.75vh;
        padding-bottom: 0;
        background-color: $c-surface_surface_white;
        opacity: 1;
        transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out, color 0.2s ease-in-out;
        color: #000;
        .title {
          font-size: 1vw;
        }
        &.faded {
          opacity: 0.3;
        }
        &.correct {
          background-color: $c-status_green_100;
          color: $c-surface_surface_white;
        }
        &.incorrect {
          background-color: $c-status_red_100;
          color: $c-surface_surface_white;
          opacity: 0.6;
        }
      }
    }
    &.answers.answered {
      .small {
        pointer-events: none;
      }
    }
  }
  .load-spinner {
    font-size: 2vw;
    color: $c-obsolete-grey_100;
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    margin-top: 3vh;
  }
  
  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }
  .tiles-header-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 10vh;
  }
  .tiles-header-content-wrapper {
    max-width: 118rem;
    margin: 0 auto;
    @media only screen and (min-width: 1232px) {
      padding-left: 3.2rem;
      padding-right: 3.2rem;
    }
    @media only screen and (max-width: 1231px) {
      display: none;
    }
    &.topic-select {
      max-width: none;
      padding-left: 5vw;
      padding-right: 5vw;
    }
  }
  .tiles-header-tile {
    margin: 0 auto;
  }
  .quiz-wrapper {
    padding-top: 5vh;
    .quiz-right {
      float: right;
      width: 42%;
      padding-right: 5%;
      text-align: left;
      .explanation-title {
        font-size: 1.4vw;
        font-weight: 500;
        line-height: 1.5em;
        letter-spacing: 0em;
        b {
          color: $c-hyperlink_default;
        }
      }
      .explanation {
        font-size: 0.9vw;
        font-weight: 400;
        line-height: 1.5em;
        letter-spacing: 0em;
        margin-top: 12px;
        margin-bottom: 12px;
      }
      .explanation-graph {
        img {
          margin-top: 16px;
          max-height: 30vh;
        }
      }
    }
    .quiz-buttons {
      clear: left;
      padding-top: 5vh;
      width: 55%;
      padding-left: 5%;
      button {
        font-size: 1vw;
        margin-left: 1em;
        margin-right: 1em;
      }
    }
  }
  .title.result-title {
    margin-top: 25vh;
  }
  .quiz-restart-btn {
    margin-top: 15vh;
    float: left;
  }
  .topic-select {
    .tiles-header-stack {
      gap: 1vw;
    }
    .tile.small {
      width: 19vw;
      height: 8vw;
      padding-bottom: 1vw;
      border-radius: 0.2vw;
      .tile-content-small {
        margin-left: 1.25vw;
        .header-tile {
          .icon {
            font-size: 1.9vw;
            line-height: 1.2em;
            margin-top: 0.85vw;
          }
          .text {
            font-size: 0.7vw;
            margin: 0.4vw 0;
            line-height: 1.5em;
          }
          .title {
            font-size: 0.95vw;
            line-height: 1em;
            margin: 0.4vw 0 0.6vw 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .tiles-placeholder-large {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .tiles-stack {
      justify-content: center; 
      align-items: center;
    }
  }
}
