.page-pulses {
  .status-unknown {
    color: $c-surface_surface_80;
  }
  .status-ok {
    color: $c-status_green_100;
  }
  .status-error {
    color: $c-status_red_100;
  }
  .status-message {
    color: $c-surface_surface_80;
    font-size: 12px;
  }
  .status-cell{
    line-height: 1.5rem;
  }
  .thin-row{
    line-height: 2rem;
  }
  .side-panel-loading {
    background-color: $c-status_orange_12;
    border: 1px solid $c-surface_surface_20;
    border-radius: 4px;
    padding: 1rem;
    margin: 2rem;
    font-size: 2rem;
    text-align: center;
  }
}
