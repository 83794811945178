.page-detail-pulse {
  .question-tile {
    background: $c-surface_surface_white;
    box-shadow: 0px 1px 3px $box-shadow;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 8px;
    text-align: left;
    font-size: 12px;
  }
  .label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: left;
  }
  .main-tile {
    background: $c-surface_surface_white;
    box-shadow: 0px 1px 3px $box-shadow;
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 8px;
    text-align: left;
    font-size: 16px;
  }
  .status-ok {
    color: $c-status_green_100;
    font-size: 14px;
    margin-left: 5px;
  }
  .status-error {
    color: $c-status_red_100;
    font-size: 14px;
    margin-left: 5px;
  }
  .preview-table {
    background-color: $c-surface_surface_0;
    border: 1px solid $c-surface_surface_50;
    th {
      border-bottom: 1px solid $c-surface_surface_50;
      padding: 2px;
    }
    td {
      padding: 2px;
    }
  }
  .agent-label {
    font-size: 16px;
    .red {
      color: $c-status_red_100;
    }
    .green {
      color: $c-status_green_100;
    }
    .blue {
      color: $c-status_blue_100;
    }
    .gray {
      color: $c-surface_surface_70;
    }
  }
  .agent-ul {
    list-style-type: none;
    padding: 0;
    li {
      margin-bottom: 4px;
      padding-left: 2rem;
    }
  }
  .script-window {
    overflow-y: scroll;
    max-height: 150px;
    background-color: $c-surface_surface_0;
    border: 1px solid $c-surface_surface_50;
    padding: 4px;
  }
  .markdown-window {
    padding: 4px;
    background-color: $c-surface_surface_0;
    border: 1px solid $c-surface_surface_50;

    .wmde-markdown {
      background-color: $c-surface_surface_0;
      color: $c-surface_surface_100;
      font-family: "IBM Plex Sans", "Segoe UI", "Arial", sans-serif;
      font-size: 12px;
      h1 {
        font-size: 17px;
      }
      h2 {
        font-size: 16px;
      }
      h3 {
        font-size: 15px;
      }
      h4 {
        font-size: 14px;
      }
      h5 {
        font-size: 13px;
      }
      pre {
        background-color: $c-surface_surface_0;
        color: $c-surface_surface_100;
        .copied{
          background-color: $c-surface_surface_50;
        }
      }
    }
  }

  .form-tile {
    background: $c-surface_surface_white;
    box-shadow: 0px 1px 3px $box-shadow;
    border-radius: 4px;
    padding: 8px;
    margin: 0px 0px 12px 0px;
    text-align: left;
  }
}
