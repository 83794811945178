.p-avatar {
  background-color: $c-primary_teal_hover;
  color: $c-surface_surface_white;
  font-size: 1.4em;
  height: 3.3em;
  width: 3.3em;

  &.p-avatar-circle {
    border-radius: 50%;
  }

  &.p-avatar-lg {
    width: 4em;
    height: 4em;

    font-size: 1.6em;
  }

  &.p-avatar-xl {
    width: 7em;
    height: 7em;

    font-size: 3.1em;
    border: solid 2px $c-surface_surface_white;
    border-width: 3px;
  }

  .p-avatar-text {
    font-size: 1.5em;
  }
}